import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Link } from 'gatsby';
import axios from 'axios';

import SEO from '../components/Seo';
import Title from '../components/Title';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
    },
  }),
);

const IndexPage = (): React.ReactNode => {
  const classes = useStyles();

  React.useEffect(() => {
    axios.get('/api/wakeup').catch((e) => {
      console.error('Wake up api call failed: ', e);
    });
  }, []);

  return (
    <div className={classes.root}>
      <Grid container spacing={4} justify="center">
        <SEO title="Home" />

        <Grid item xs={12}>
          <Title />
        </Grid>

        <Grid item xs={12}>
          <Typography align="center">Track your expenses easily and securely.</Typography>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Button variant="contained" color="primary" component={Link} to="/add" fullWidth>
            Get started
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default IndexPage;
